import React from "react";

import "./avatar-list.scss";

/**
 * The maximum player avatars to show before truncating.
 */
const DEFAULT_MAX_PLAYER_SHOWN = 7;

const TournamentAvatarList = ({ avatars, maxVisible }) => {
  if (!avatars) return null;
  const maxVis = maxVisible || DEFAULT_MAX_PLAYER_SHOWN;

  return (
    <div className="avatar-list">
      {avatars.map((avatar, index) => {
        if (index >= maxVis) return null;

        return (
          <div
            key={index}
            className="avatar-list__circle avatar-list__circle--avatar"
          >
            {avatar.avatar && <img src={avatar.avatar} alt={avatar.name} />}
          </div>
        );
      })}
      {avatars.length - maxVis > 0 && (
        <div className="avatar-list__circle avatar-list__circle--etc">{`+${
          avatars.length - maxVis
        }`}</div>
      )}
    </div>
  );
};

export default TournamentAvatarList;